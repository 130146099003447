/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import {
  ErrorHandler,
  JwtTokenUtils,
  StorageHelper,
  usePromise,
} from '@atfm/utils';
import Keycloak from 'keycloak-js';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { globalAuthConfig, initAuthConfig } from '../../authentication/config';
import { StorageKeys } from '../../constants';
import { interceptors } from '../../store/api/services';
import { setAuthenticationData } from '../../store/authentication/actions';

const tokenMinValidity =
  parseInt(window.env.OIDC_TOKEN_MIN_VALIDITY_MINS, 10) * 15;

const useAuthDefaultContext = () => {
  const dispatch = useDispatch();

  const { current: keycloakClient } = useRef(new Keycloak(globalAuthConfig));
  const { current: initialization } = usePromise();

  const initialize = async (kc) => {
    try {
      const authenticated = await kc.init(initAuthConfig);

      if (!authenticated) {
        const loggedIn = await kc.login();
        initialization.resolve(loggedIn);
        return loggedIn;
      }

      initialization.resolve(authenticated);
      return authenticated;
    } catch (e) {
      ErrorHandler.logError(e);

      initialization.reject(e);
      throw e;
    }
  };

  useEffect(() => {
    const doInitialize = async () => {
      interceptors.headers.push(async (headers) => {
        if (headers.Authorization) {
          // doesn't override provided Authorization header
          return headers;
        }

        await initialization.promise;

        const putNewTokenInStorage = ({ tokenParsed, token }) => {
          StorageHelper.storeItem(StorageKeys.PARSED_TOKEN, tokenParsed);
          StorageHelper.storeItem(StorageKeys.BEARER_TOKEN, token);
        };

        let bearerToken = StorageHelper.retrieveItem(StorageKeys.BEARER_TOKEN);
        const storedToken = StorageHelper.retrieveItem(
          StorageKeys.PARSED_TOKEN,
        );
        try {
          if (!storedToken) {
            await keycloakClient.updateToken(-1);
            putNewTokenInStorage(keycloakClient);
            bearerToken = keycloakClient.token;
          } else {
            const refreshed = await keycloakClient.updateToken(
              tokenMinValidity,
            );
            if (refreshed) {
              putNewTokenInStorage(keycloakClient);
            }
          }
        } catch (e) {
          console.error('Error while updating token', e);
          keycloakClient.clearToken();
        }

        return {
          ...headers,
          Authorization: `Bearer ${bearerToken}`,
        };
      });

      await initialize(keycloakClient);

      const parsedToken = keycloakClient.tokenParsed;
      const accessToken = keycloakClient.token;

      const authData = {
        ...JwtTokenUtils.getAuthenticationDataFromParsedToken(parsedToken),
        ...JwtTokenUtils.getCdmDataFromParsedToken(
          parsedToken,
          window.env.EXECUTION_ENV,
        ),
      };

      await dispatch(
        setAuthenticationData({ accessToken, parsedToken, ...authData }),
      );
    };

    doInitialize();
  }, []);

  return {
    initialization: initialization.promise,
    async login() {
      await initialization.promise;
      return keycloakClient.login();
    },
    async logout() {
      await initialization.promise;
      return keycloakClient.logout();
    },
  };
};

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const context = useAuthDefaultContext();
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default AuthContext;
export const useAuthContext = () => useContext(AuthContext);
