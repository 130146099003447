import React, { useRef, useEffect, useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { MenuStatus } from '../../constants';

export const DefaultWidth = Object.freeze({
  FLIGHT_LIST: 900,
  APPROACH: 525,
  TRAFFIC_LOAD: 780,
  TMI_SUMMARY: 496,
  CDM: 496,
  CONFIG: 550,
});

const AppContext = createContext();

const usePrevious = (data) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = data;
  }, [data]);
  return ref.current;
};

export const useAppDefaultContext = () => {
  // not exposed
  const approachWidth = DefaultWidth.APPROACH;
  const trafficLoadWidth = DefaultWidth.TRAFFIC_LOAD;
  const tmiSummaryWidth = DefaultWidth.TMI_SUMMARY;
  const cdmWidth = DefaultWidth.CDM;
  const configWidth = DefaultWidth.CONFIG;

  const [leftWidth, setLeftWidth] = useState(0);

  // exposed (except setters)
  const flightWidth = DefaultWidth.FLIGHT_LIST;

  const [contentLeft, setContentLeft] = useState(leftWidth);
  const [leftOpen, setLeftOpen] = useState(false);

  const [rightWidth, setRightWidth] = useState(0);
  const prevRightWidth = usePrevious(rightWidth);
  const [contentRight, setContentRight] = useState(rightWidth);
  const [rightOpen, setRightOpen] = useState(false);

  const [cdmActive, setCdmActive] = useState(false);
  const [approachActive, setApproachActive] = useState(false);
  const [isFlightListActive, setIsFlightListActive] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [isMonitoringActive, setIsMonitoringActive] = useState(true);
  const [planningActive, setPlanningActive] = useState(false);
  const [pretactActive, setPretactActive] = useState(false);
  const [replayActive, setReplayActive] = useState(false);
  const [sectoLoadsActive, setSectoLoadsActive] = useState(false);
  const [simulationsListActive, setSimulationsListActive] = useState(false);
  const [showHotspotDetails, setShowHotspotDetails] = useState(false);
  const [trafficLoadActive, setTrafficLoadActive] = useState(false);
  const [trafficLoadComparisonActive, setTrafficLoadComparisonActive] = useState(false);
  const prevTrafficLoadComparisonActive = usePrevious(trafficLoadComparisonActive);
  const [whatifActive, setWhatifActive] = useState(false);
  const prevWhatifActive = usePrevious(whatifActive);
  const [measureUpdated, setMeasureUpdated] = useState(false);

  useEffect(() => {
    const isLeftOpen = !trafficLoadComparisonActive && (
      isFlightListActive || sectoLoadsActive || trafficLoadActive || cdmActive || simulationsListActive || approachActive
    );
    setLeftOpen(isLeftOpen);

    setContentRight(rightOpen ? rightWidth : 0);
    if (!isMonitoringActive && !sectoLoadsActive) {
      setContentLeft(0);
    } else {
      setContentLeft(isLeftOpen ? leftWidth : 0);
    }
  }, [
    cdmActive, approachActive, simulationsListActive, trafficLoadComparisonActive, isFlightListActive,
    sectoLoadsActive, trafficLoadActive, leftOpen, leftWidth, planningActive, rightOpen,
    rightWidth, pretactActive, replayActive,
  ]);

  const handleWhatToShow = (whatToShow, flag) => {
    switch (whatToShow) {
      case MenuStatus.MONITORING: {
        setIsMenuVisible(true);
        setIsMonitoringActive(true);
        setPlanningActive(false);
        setPretactActive(false);
        setReplayActive(false);
        setSectoLoadsActive(false);
        break;
      }
      case MenuStatus.SECTO: {
        setLeftWidth(configWidth);
        setApproachActive(false);
        setCdmActive(false);
        setIsFlightListActive(false);
        setIsMenuVisible(true);
        setIsMonitoringActive(false);
        setPlanningActive(false);
        setPretactActive(false);
        setReplayActive(false);
        setSectoLoadsActive(true);
        setSimulationsListActive(false);
        setTrafficLoadActive(false);
        setTrafficLoadComparisonActive(false);
        setWhatifActive(false);
        break;
      }
      case MenuStatus.PLANNING: {
        setApproachActive(false);
        setCdmActive(false);
        setIsFlightListActive(false);
        setIsMonitoringActive(false);
        setPlanningActive(true);
        setPretactActive(false);
        setReplayActive(false);
        setSectoLoadsActive(false);
        setSimulationsListActive(false);
        setTrafficLoadActive(false);
        setTrafficLoadComparisonActive(false);
        setWhatifActive(false);
        break;
      }
      case MenuStatus.PRETACT: {
        setCdmActive(false);
        setIsFlightListActive(false);
        setIsMenuVisible(false);
        setIsMonitoringActive(false);
        setPlanningActive(false);
        setPretactActive(true);
        setReplayActive(false);
        setSectoLoadsActive(false);
        setSimulationsListActive(false);
        setTrafficLoadActive(false);
        setTrafficLoadComparisonActive(false);
        setWhatifActive(false);
        break;
      }
      case MenuStatus.REPLAY: {
        setApproachActive(false);
        setCdmActive(false);
        setIsFlightListActive(false);
        setIsMenuVisible(false);
        setIsMonitoringActive(false);
        setPlanningActive(false);
        setPretactActive(false);
        setReplayActive(true);
        setSectoLoadsActive(false);
        setSimulationsListActive(false);
        setTrafficLoadActive(false);
        setTrafficLoadComparisonActive(false);
        setWhatifActive(false);
        break;
      }
      case MenuStatus.FLIGHTS: {
        const widthApproach = approachActive ? approachWidth : 0;
        const widthFlight = flag ? flightWidth : 0;
        setLeftWidth(widthApproach + widthFlight);
        setCdmActive(false);
        setIsFlightListActive(flag);
        setIsMonitoringActive(true);
        setPlanningActive(false);
        setPretactActive(false);
        setReplayActive(false);
        setSectoLoadsActive(false);
        setShowHotspotDetails(false);
        setSimulationsListActive(false);
        setTrafficLoadActive(false);
        setTrafficLoadComparisonActive(false);
        break;
      }
      case MenuStatus.TRAFFIC: {
        setLeftWidth(flag ? trafficLoadWidth : 0);
        setCdmActive(false);
        setApproachActive(false);
        setIsFlightListActive(false);
        setIsMonitoringActive(true);
        setPlanningActive(false);
        setPretactActive(false);
        setReplayActive(false);
        setSectoLoadsActive(false);
        setSimulationsListActive(false);
        setTrafficLoadActive(flag);
        setTrafficLoadComparisonActive(false);
        setWhatifActive(false);
        break;
      }
      case MenuStatus.CDM: {
        setLeftWidth(flag ? cdmWidth : 0);
        setCdmActive(flag === undefined ? cdmActive : flag);
        setApproachActive(false);
        setIsFlightListActive(false);
        setIsMonitoringActive(true);
        setPlanningActive(false);
        setPretactActive(false);
        setReplayActive(false);
        setSectoLoadsActive(false);
        setSimulationsListActive(false);
        setTrafficLoadActive(false);
        break;
      }
      case MenuStatus.APPROACH: {
        const widthFlight = isFlightListActive ? flightWidth : 0;
        const widthApproach = flag ? approachWidth : 0;
        setLeftWidth(widthFlight + widthApproach);
        setApproachActive(flag === undefined ? approachActive : flag);
        setCdmActive(false);
        setIsMonitoringActive(true);
        setPlanningActive(false);
        setReplayActive(false);
        setSectoLoadsActive(false);
        setShowHotspotDetails(false);
        setSimulationsListActive(false);
        setTrafficLoadActive(false);
        setTrafficLoadComparisonActive(false);
        break;
      }
      case MenuStatus.TMI: {
        setLeftWidth(flag ? tmiSummaryWidth : 0);
        setCdmActive(false);
        setApproachActive(false);
        setIsFlightListActive(false);
        setIsMonitoringActive(true);
        setPlanningActive(false);
        setPretactActive(false);
        setReplayActive(false);
        setSectoLoadsActive(false);
        setSimulationsListActive(flag === undefined ? false : flag);
        setTrafficLoadActive(false);
        break;
      }
      case MenuStatus.WHATIF: {
        if (whatifActive !== flag && !sectoLoadsActive && !trafficLoadActive) {
          setWhatifActive(flag);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return {
    handleWhatToShow,
    approachWidth,
    flightWidth,
    contentLeft,
    setContentLeft,
    leftOpen,
    setLeftOpen,
    rightWidth,
    setRightWidth,
    prevRightWidth,
    contentRight,
    setContentRight,
    rightOpen,
    setRightOpen,
    cdmActive,
    setCdmActive,
    approachActive,
    setApproachActive,
    isFlightListActive,
    setIsFlightListActive,
    isMenuVisible,
    setIsMenuVisible,
    isMonitoringActive,
    setIsMonitoringActive,
    planningActive,
    setPlanningActive,
    pretactActive,
    setPretactActive,
    replayActive,
    setReplayActive,
    sectoLoadsActive,
    setSectoLoadsActive,
    simulationsListActive,
    setSimulationsListActive,
    showHotspotDetails,
    setShowHotspotDetails,
    trafficLoadActive,
    setTrafficLoadActive,
    trafficLoadComparisonActive,
    setTrafficLoadComparisonActive,
    prevTrafficLoadComparisonActive,
    whatifActive,
    setWhatifActive,
    prevWhatifActive,
    measureUpdated,
    setMeasureUpdated,
  };
};

export const AppProvider = ({ children }) => {
  const context = useAppDefaultContext();
  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default AppContext;
export const useAppContext = () => useContext(AppContext);
