import { currentAddress, Routes } from '../constants';
import { getHash, getPath } from '../utils/LocationUtil';

const PATHS_TO_IGNORE = [Routes.ROOT, Routes.OAUTH, Routes.LOGIN];
const PATHS_TO_SKIP = [Routes.OAUTH, Routes.LOGIN];

const currentPath = getPath(window.location.href, PATHS_TO_SKIP);
const redirectPath = PATHS_TO_IGNORE.some(p => currentPath === p) ? '' : `${currentPath}`;

const currentHash = getHash(window.location.href);
const redirectHash = currentHash === '' ? '' : `#${currentHash}`;

const redirectUri = `${currentAddress}${redirectPath}/oauth/login${redirectHash}`;
const checkLoginIframe = window.env.OIDC_CHECK_LOGIN_IFRAME === 'true';

export const globalAuthConfig = {
  realm: window.env.OIDC_REALM,
  url: window.env.OIDC_URL,
  clientId: window.env.OIDC_CLIENT_ID,
};

export const initAuthConfig = {
  redirectUri,
  checkLoginIframe,
  scope: window.env.OIDC_SCOPE,
  flow: window.env.OIDC_FLOW,
  responseMode: window.env.OIDC_RESPONSE_MODE,
  silentCheckSsoRedirectUri: checkLoginIframe ? `${window.location.origin}/static/check-sso.html` : undefined,
  enableLogging: window.env.OIDC_ENABLE_LOGGING === 'true',
};
