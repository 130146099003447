import moment from 'moment';
import SectoConfigList from '../../containers/SectoConfigList/SectoConfigList';
import {
  LOADING_SECTORS_DATA,
  LOADING_SECTORS_DATA_SUCCESS,
  LOADING_SECTORS_DATA_FAILURE,
  LOADING_POSSIBLE_CONFS,
  LOADING_POSSIBLE_CONFS_SUCCESS,
  LOADING_POSSIBLE_CONFS_FAILURE,
  UPDATING_SECTORS_CONF,
  UPDATING_SECTORS_CONF_SUCCESS,
  UPDATING_SECTORS_CONF_FAILURE,
  SECTOR_EDIT_ERROR,
  CLEAR_SECTOR_EDIT_ERROR,
  SELECTED_CONFIG,
  CLEAR_SELECTED_CONFIG,
  CHANGED_CURRENT_PLAN,
  LOADING_RELATIONSHIP_DATA,
  LOADING_RELATIONSHIP_DATA_SUCCESS,
  LOADING_RELATIONSHIP_DATA_FAILURE,
  CLEAR_RELATIONSHIP_DATA,
  SELECT_SECTO,
  CLEAR_SECTO,
  UPDATE_SELECTED_CTA,
} from './actions';

const didNotChange = (objectA, objectB) => JSON.stringify(objectA) === JSON.stringify(objectB);

export default (state = {
  sectorEditError: false,
  sectorEditErrorMessage: '',
  isSavingConfigs: false,
  selectedConfigData: null,
  currentPlan: null,
  selectedCTA: null,
  relationshipData: null,
  selectedSecto: undefined,
  data: undefined,
}, action = {}) => {
  switch (action.type) {
    case LOADING_SECTORS_DATA: {
      return state;
    }
    case LOADING_SECTORS_DATA_SUCCESS: {
      const { selectedCTA } = state;
      const { sectorsData } = action;

      if (selectedCTA) {
        const updatedCTA = sectorsData.find(item => item.name === selectedCTA.name);

        if (didNotChange(selectedCTA, updatedCTA)) {
          return {
            ...state,
            data: sectorsData,
          };
        }

        return {
          ...state,
          data: sectorsData,
          selectedCTA: updatedCTA,
        };
      }

      return {
        ...state,
        data: sectorsData,
        selectedCTA: sectorsData[0],
      };
    }
    case LOADING_SECTORS_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_POSSIBLE_CONFS: {
      return state;
    }
    case LOADING_POSSIBLE_CONFS_SUCCESS: {
      return { ...state, possibleConfs: action.possibleConfs[0].conf_names.sort() };
    }
    case LOADING_POSSIBLE_CONFS_FAILURE: {
      return { ...state, error: action.error };
    }
    case SELECT_SECTO: {
      return { ...state, selectedSecto: action.sectoData };
    }
    case CLEAR_SECTO: {
      return { ...state, selectedSecto: undefined };
    }
    case CLEAR_RELATIONSHIP_DATA: {
      return { ...state, relationshipData: null };
    }
    case LOADING_RELATIONSHIP_DATA: {
      return state;
    }
    case LOADING_RELATIONSHIP_DATA_SUCCESS: {
      return { ...state, relationshipData: action.relationshipData };
    }
    case LOADING_RELATIONSHIP_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case UPDATING_SECTORS_CONF: {
      return { ...state, isSavingConfigs: true };
    }
    case UPDATING_SECTORS_CONF_SUCCESS: {
      const prevConfs = state.data[0].confs;

      // only one day of configurations is received
      const { confs } = action.sectorsData;
      const sectoPlanDay = SectoConfigList.calcStartTimesList(confs);

      // filter out the old configurations of the received day
      const filteredConfs = prevConfs.filter((conf) => {
        const confDayToFilter = moment.utc(conf.from).hour(0).minute(0).valueOf();
        return confDayToFilter !== sectoPlanDay[0];
      });

      const updatedConf = filteredConfs.concat(confs).sort((a, b) => a.from - b.from);

      const updatedData = { ...action.sectorsData, confs: updatedConf };
      return { ...state, data: updatedData, isSavingConfigs: false };
    }
    case UPDATING_SECTORS_CONF_FAILURE: {
      return { ...state, error: action.error, isSavingConfigs: false };
    }
    case SECTOR_EDIT_ERROR: {
      return { ...state, sectorEditError: true, sectorEditErrorMessage: action.errorMessage };
    }
    case CLEAR_SECTOR_EDIT_ERROR: {
      return { ...state, sectorEditError: false, sectorEditErrorMessage: '' };
    }
    case SELECTED_CONFIG: {
      return { ...state, selectedConfigData: action.selectedConfigData };
    }
    case CLEAR_SELECTED_CONFIG: {
      return { ...state, selectedConfigData: null };
    }
    case CHANGED_CURRENT_PLAN: {
      const newPlanWithSectors = action.newPlan.map((conf) => {
        const confAlreadyInOldPlan = state.data[0].confs.find(realConf => realConf.conf_name === conf.conf_name);
        let retrieveSectorsFromOriginalPlan = confAlreadyInOldPlan ? confAlreadyInOldPlan.sectors : undefined;

        if (retrieveSectorsFromOriginalPlan === undefined && state.currentPlan) {
          const confAlreadyInCurrentPlan = state.currentPlan.find(realConf => realConf.conf_name === conf.conf_name);
          retrieveSectorsFromOriginalPlan = confAlreadyInCurrentPlan ? confAlreadyInCurrentPlan.sectors : undefined;
        }

        const keepSectors = conf.sectors ? conf.sectors : retrieveSectorsFromOriginalPlan;
        return { ...conf, sectors: keepSectors };
      });
      return { ...state, currentPlan: newPlanWithSectors };
    }
    case UPDATE_SELECTED_CTA: {
      const ctaExists = state.data.find(profile => profile.name === action.cta);

      if (ctaExists) {
        return { ...state, selectedCTA: ctaExists };
      }
      return { ...state };
    }
    default: {
      return state;
    }
  }
};
