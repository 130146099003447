import { StorageHelper } from '@atfm/utils';
import { StorageKeys } from '../../constants';

export const END_SIGNIN = 'END_SIGNIN';
export function endSignIn() {
  return { type: END_SIGNIN };
}

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export function endSignOut() {
  return { type: SIGNOUT_REQUEST };
}

export const setAuthenticationData = ({ accessToken, parsedToken, name, username, email, cdmUserAccessToken, cdmPublishMeasuresChannelId }) => (dispatch) => {
  StorageHelper.storeItem(StorageKeys.PARSED_TOKEN, parsedToken);
  StorageHelper.storeItem(StorageKeys.BEARER_TOKEN, accessToken);
  StorageHelper.storeItem('name', name);
  StorageHelper.storeItem('username', username);
  StorageHelper.storeItem('email', email);
  StorageHelper.storeItem('userRoles', parsedToken.groups);
  StorageHelper.storeItem('cdm_userAccessToken', cdmUserAccessToken);
  StorageHelper.storeItem('cdm_publishMeasuresChannelId', cdmPublishMeasuresChannelId);
  dispatch(endSignIn());
};

export const logout = () => (dispatch) => {
  StorageHelper.clearItem(StorageKeys.PARSED_TOKEN);
  StorageHelper.clearItem(StorageKeys.BEARER_TOKEN);
  StorageHelper.clearItem('ad_refresh_token');
  StorageHelper.clearItem('name');
  StorageHelper.clearItem('username');
  StorageHelper.clearItem('email');
  StorageHelper.clearItem('userRoles');
  StorageHelper.clearItem('cdm_userAccessToken');
  StorageHelper.clearItem('cdm_publishMeasuresChannelId');
  dispatch(endSignOut());
};
